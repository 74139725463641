// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED
import { defineComponent } from 'vue'
import { mapActions, mapMutations } from 'vuex'
import { AlertType } from '@/_typings/enums/alertType'
import { IColumn } from '@/_typings/interfaces/column'
import { IPagedList } from '@/_typings/interfaces/paged-list'
import { IUser } from '@/_typings/interfaces/user'
import confirmationTypes from '@/components/modals/confirmation/confirmationTypes'
import showConfirmationModal from '@/utils/showConfirmationModal'
import showReassignModal from '@/utils/showReassignModal'
import Badge from '@/components/controls/badge/index.vue'
import GridTable from '@/components/controls/grid-table/index.vue'
import Reassign from '@/components/modals/reassign/index.vue'
import { getOpportunityStatusBadgeInfo } from '@/utils/getBadgeInfo'

export default defineComponent({
  name: 'opportunities',
  components: {
    Badge,
    GridTable,
    Reassign
  },
  getOpportunityStatusBadgeInfo,
  data() {
    return {
      pagedList: {} as IPagedList,
      selectedItems: [] as any[]
    }
  },
  created() {
    this.getPagedItems({ sortedBy: 'name', sortDirection: 'Ascending' })
  },
  computed: {
    columns() : IColumn[] {
      return [
        { id: 'name', dataType: 'String', label: this.$t('opportunity_name_label'), name: 'name', isHyperlink: true },
        { id: 'accountName', dataType: 'String', label: this.$t('opportunity_account_label'), name: 'accountName' },
        { id: 'ownerName', dataType: 'String', label: this.$t('opportunity_owner_label'), name: 'ownerName' },
        { id: 'status', dataType: 'String', label: this.$t('opportunity_status_label'), name: 'status', width: '4rem', customRender: 'status' },
        { id: 'estimatedValue', dataType: 'Currency', label: this.$t('opportunity_estimated-value-abbreviated_label'), name: 'estimatedValue', width: '7rem' },
        { id: 'estimatedCloseDate', dataType: 'Date', label: this.$t('opportunity_estimated-close-date-abbreviated_label'), name: 'estimatedCloseDate', width: '11rem' }
      ] as IColumn[]
    }
  },
  methods: {
    ...mapMutations([
      'setAlert'
    ]),
    ...mapActions([
      'deleteOpportunities',
      'deleteOpportunity',
      'fetchOpportunities',
      'reassignOpportunities'
    ]),
    deleteItem({ id }) {
      showConfirmationModal(confirmationTypes.DELETE, () => {
        this.deleteOpportunity({ opportunityId: id }).then(() => {
          this.getPagedItems({})
          this.selectedItems = this.selectedItems.filter(selectedItem => selectedItem.id !== id)
        })
      })
    },
    deleteItems() {
      showConfirmationModal(confirmationTypes.DELETE_MULTIPLE, () => {
        this.deleteOpportunities({ opportunityIds: this.selectedItems.map(selectedItem => selectedItem.id) }).then(() => {
          this.getPagedItems({})
          this.selectedItems = []
        })
      })
    },
    getPagedItems(pageInfo: any) {
      this.fetchOpportunities({ pageInfo: { ...this.pagedList.paging, ...pageInfo } }).then(data => {
        this.pagedList = data
      })
    },
    navigateToOpportunity(id) {
      this.$router.push({ name: id ? 'opportunity' : 'opportunity-new', params: { opportunityId: id ? id : null } })
    },
    reassignItems(targetOwner: IUser) {
      this.reassignOpportunities({ ids: this.selectedItems.map(item => item.id), ownerId: targetOwner.id }).then(() => {
        this.getPagedItems({})
        this.selectedItems = []
        this.setAlert({ type: AlertType.Success, title: this.$t('reseller_reassign-1_message'), message: this.$t('reseller_reassign-2_message'), timeout: 3000 })
      })
    },
    showReassignDialog() {
      showReassignModal(this.selectedItems.map(item => item.id))
    }
  }
})
