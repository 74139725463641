// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED
import { defineComponent } from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { AlertType } from '@/_typings/enums/alertType'
import { BadgeType } from '@/_typings/enums/badgeType'
import { IAccount } from '@/_typings/interfaces/account'
import { IColumn } from '@/_typings/interfaces/column'
import { IDropdownItem } from '@/_typings/interfaces/dropdown-item'
import { IOpportunity, IOpportunityLine } from '@/_typings/interfaces/opportunity'
import { IPagedList } from '@/_typings/interfaces/paged-list'
import confirmationTypes from '@/components/modals/confirmation/confirmationTypes'
import Badge from '@/components/controls/badge/index.vue'
import ConfiguratorCart from '@/components/misc/configurator-cart/index.vue'
import ConfiguratorCartMixin from '@/components/misc/configurator-cart/configurator-cart-mixin'
import DatePicker from '@/components/controls/date-picker/index.vue'
import Dropdown from '@/components/controls/drop-down/index.vue'
import GridTable from '@/components/controls/grid-table/index.vue'
import NumberInput from '@/components/controls/number-input/index.vue'
import TextInput from '@/components/controls/text-input/index.vue'
import getPageTitle from '@/utils/getPageTitle'
import showConfirmationModal from '@/utils/showConfirmationModal'
import Localization from '@/utils/localization'
import { getOpportunityStatusBadgeInfo, getQuoteStatusBadgeInfo } from '@/utils/getBadgeInfo'
import JQuery from 'jquery'

interface IOpportunityStatus {
  id: string
  action: string;
  reason: string;
}

export default defineComponent({
  name: 'opportunity',
  components: {
    Badge,
    ConfiguratorCart,
    DatePicker,
    Dropdown,
    GridTable,
    NumberInput,
    TextInput
  },
  mixins: [ ConfiguratorCartMixin ],
  BadgeType,
  getPageTitle,
  getOpportunityStatusBadgeInfo,
  getQuoteStatusBadgeInfo,
  Localization,
  data() {
    return {
      accountList: {} as IPagedList,
      contactList: {} as IPagedList,
      isCartReady: false,
      isSaving: false,
      opportunity: {} as IOpportunity,
      opportunityStatus: {} as IOpportunityStatus,
      opportunityStatuses: [] as (IDropdownItem & { action: string })[],
      readonly: false,
      selectedLineItems: [] as any[],
      selectedQuoteItems: [] as any[],
      userList: {} as IPagedList,
      validatorKey: 0
    }
  },
  created() {
    if (!!this.$route.params.opportunityId) { // view / edit
      this.fetchOpportunity({ opportunityId: this.$route.params.opportunityId }).then(data => {
        this.opportunity = data
        if (!this.opportunity.received) this.receiveOpportunity({ opportunityId: this.opportunity.id })
      })
      this.readonly = true
    } else { // new
      this.opportunity = { status: 'Active' } as IOpportunity
      this.fetchLoggedOnUser().then(data => { this.opportunity.owner = data })
    }
    this.fetchAccounts({ pageInfo: { pageSize: 250, sortedBy: 'name', sortDirection: 'Ascending' }, opportunityId: this.$route.params.opportunityId, activeOnly: true }).then(data => { this.accountList = data })
    this.fetchContacts({ pageInfo: { pageSize: 250, sortedBy: 'fullName', sortDirection: 'Ascending' }, opportunityId: this.$route.params.opportunityId, activeOnly: true }).then(data => { this.contactList = { ...data, items: data.items.map(item => { return { ...item, name: item.fullName } }) } })
    this.fetchUsers({ pageInfo: { pageSize: 250, sortedBy: 'fullName', sortDirection: 'Ascending' } }).then(data => { this.userList = { ...data, items: data.items.map(item => { return { ...item, name: item.fullName } }) } })
  },
  updated() {
    JQuery(function () {
      JQuery('[data-toggle="tooltip"]').tooltip({ placement: 'top', customClass: 'tooltip', trigger: 'hover', delay: { show: 800, hide: 100 } })
    })
  },
  computed: {
    ...mapGetters({
      user: 'getUser'
    }),
    isLocked(): boolean {
      return this.opportunity.status === 'Won' || this.opportunity.status === 'Lost'
    },
    lineColumns() : IColumn[] {
      return [
        { id: 'sku', dataType: 'String', label: this.$t('opportunity-line_sku_column-header'), name: 'sku', isHyperlink: true },
        { id: 'name', dataType: 'String', label: this.$t('opportunity-line_name_column-header'), name: 'name' },
        { id: 'quantity', dataType: 'Numeric', label: this.$t('opportunity-line_quantity_column-header'), name: 'quantity', width: '10rem' },
        { id: 'unitCost', dataType: 'Currency', label: this.$t('opportunity-line_unit-cost_column-header'), name: 'unitCost', width: '10rem' },
        { id: 'unitPrice', dataType: 'Currency', label: this.$t('opportunity-line_unit-price_column-header'), name: 'unitPrice', width: '10rem' },
        { id: 'extendedSalesPrice', dataType: 'Currency', label: this.$t('opportunity-line_extended-sales-price-abbreviated_label'), name: 'extendedSalesPrice', width: '10rem' }
      ] as IColumn[]
    },
    quoteColumns() : IColumn[] {
      return [
        { id: 'name', dataType: 'String', label: this.$t('quote_name_label'), name: 'name', isHyperlink: true, customRender: 'name' },
        { id: 'ownerName', dataType: 'String', label: this.$t('opportunity_owner_label'), name: 'ownerName' },
        { id: 'status', dataType: 'String', label: this.$t('quote_status_label'), name: 'status', width: '4rem', customRender: 'status' },
        { id: 'extendedCost', dataType: 'Currency', label: this.$t('quote_extended-cost-abbreviated_label'), name: 'extendedCost', width: '7rem' },
        { id: 'extendedSalesPrice', dataType: 'Currency', label: this.$t('quote_extended-sales-price-abbreviated_label'), name: 'extendedSalesPrice', width: '10rem' }
      ] as IColumn[]
    }
  },
  methods: {
    ...mapMutations([
      'setAlert',
      'setBreadcrumbText'
    ]),
    ...mapActions([
      'addOpportunity',
      'deleteOpportunityLine',
      'deleteOpportunityLines',
      'deleteQuote',
      'deleteQuotes',
      'fetchAccounts',
      'fetchContacts',
      'fetchLoggedOnUser',
      'fetchOpportunity',
      'fetchOpportunityStatuses',
      'fetchOpportunityLines',
      'fetchOpportunityQuotes',
      'fetchUsers',
      'receiveOpportunity',
      'updateOpportunity',
      'updateOpportunityStatus'
    ]),
    changeAccount(account: IAccount) {
      this.opportunity.account = account
      this.fetchContacts({ pageInfo: { pageSize: 250, sortedBy: 'fullName', sortDirection: 'Ascending' }, opportunityId: this.$route.params.opportunityId, accountId: account.id, activeOnly: true }).then(data => { this.contactList = { ...data, items: data.items.map(item => { return { ...item, name: item.fullName } }) } })
    },
    deleteLineItem({ id }) {
      showConfirmationModal(confirmationTypes.DELETE, () => {
        this.deleteOpportunityLine({ opportunityId: this.opportunity.id, lineId: id }).then((data) => {
          this.opportunity = data
          this.selectedLineItems = this.selectedLineItems.filter(selectedItem => selectedItem.id !== id)
          this.refreshCart()
        })
      })
    },
    deleteLineItems() {
      showConfirmationModal(confirmationTypes.DELETE_MULTIPLE, () => {
        this.deleteOpportunityLines({ opportunityId: this.opportunity.id, lineIds: this.selectedLineItems.map(selectedItem => selectedItem.id) }).then((data) => {
          this.opportunity = data
          this.selectedLineItems = []
          this.refreshCart()
        })
      })
    },
    deleteQuoteItem({ id }) {
      showConfirmationModal(confirmationTypes.DELETE, () => {
        this.deleteQuote({ quoteId: id }).then(() => {
          this.getPagedQuoteItems({})
          this.selectedQuoteItems = this.selectedQuoteItems.filter(selectedItem => selectedItem.id !== id)
        })
      })
    },
    deleteQuoteItems() {
      showConfirmationModal(confirmationTypes.DELETE_MULTIPLE, () => {
        this.deleteQuotes({ quoteIds: this.selectedQuoteItems.map(selectedItem => selectedItem.id) }).then(() => {
          this.getPagedQuoteItems({})
          this.selectedQuoteItems = []
        })
      })
    },
    getPagedLineItems(pageInfo: any) {
      this.fetchOpportunityLines({ opportunityId: this.opportunity.id, pageInfo: { ...this.opportunity.lines.paging, ...pageInfo } }).then(data => {
        this.opportunity.lines = data
      })
    },
    getPagedQuoteItems(pageInfo: any) {
      this.fetchOpportunityQuotes({ opportunityId: this.opportunity.id, pageInfo: { ...this.opportunity.quotes.paging, ...pageInfo } }).then(data => {
        this.opportunity.quotes = data
      })
    },
    navigateToConfigurator(e: any) {
      this.$router.push({ name: 'opportunity-configurator', params: { opportunityId: this.opportunity.id }, query: { dealId: this.opportunity.omniDealId, cartId: this.opportunity.omniCartId, rootConfigurationId: e.omniRootConfigurationItemId || e.rootConfigurationId, configurationId: e.omniConfigurationItemId || e.configurationId } })
    },
    navigateToProductCatalog() {
      this.$router.push({ name: 'opportunity-product-catalog', params: { opportunityId: this.opportunity.id } })
    },
    navigateToQuote(id) {
      this.$router.push({ name: id ? 'opportunity-quote' : 'opportunity-quote-new', params: { opportunityId: this.opportunity.id, quoteId: id ? id : null } })
    },
    refreshCart() {
      this.$el.querySelector('.configurator-cart iframe')?.contentWindow.postMessage({ type: 'REFRESH-SESSION', reconfigureCart: true }, '*')
    },
    refreshOpportunity() {
      this.fetchOpportunity({ opportunityId: this.$route.params.opportunityId }).then(data => {
        this.opportunity = data
      })
    },
    saveOpportunity() {
      this.validatorKey++
      this.$nextTick(() => {
        if (this.$el.querySelectorAll('#opportunity .form-input .invalid').length) return
        this.isSaving = true
        const action = this.opportunity.id ? this.updateOpportunity : this.addOpportunity
        action({ opportunity: this.opportunity })
          .then((data) => {
            if (this.opportunity.id) {
              this.opportunity = data
              this.setBreadcrumbText({ id: this.opportunity.id, text: this.opportunity.name })
              this.readonly = true
            } else {
              this.$router.push({ path: '/refresh', query: { name: 'opportunity', id: data.id } })
            }
            this.setAlert({ type: AlertType.Success, title: this.$t('reseller_opportunity-save-1_message'), message: this.$t('reseller_opportunity-save-2_message'), timeout: 3000 })
          })
          .finally(() => {
            this.isSaving = false
          })
      })
    },
    saveOpportunityStatus() {
      this.updateOpportunityStatus({ opportunityId: this.opportunity.id, opportunityStatus: this.opportunityStatus }).then((data) => {
        this.opportunity = data
        if (this.isLocked) this.readonly = true
        JQuery('#opportunity-status').modal('hide')
        this.refreshCart()
      })
    },
    async showOpportunityStatusModal() {
      const data = await this.fetchOpportunityStatuses({ opportunityId: this.opportunity.id })
      this.opportunityStatuses = data.map(item => { return { id: item.status, name: this.$t('opportunity_status-' + item.status.toLowerCase() + '_label'), action: item.actionName } })
      this.opportunityStatus = {} as IOpportunityStatus
      JQuery('#opportunity-status').modal()
    }
  }
})
