// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                      
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'
import { BadgeType } from '@/_typings/enums/badgeType'
import { IPagedList } from '@/_typings/interfaces/paged-list'
import { IUser } from '@/_typings/interfaces/user'
import Badge from '@/components/controls/badge/index.vue'
import Dropdown from '@/components/controls/drop-down/index.vue'
import JQuery from 'jquery'

export default defineComponent({
  name: 'reassign',
  components: {
    Badge,
    Dropdown
  },
  BadgeType,
  emits: [ 'change-owner' ],
  data() {
    return {
      reassignIds: [] as string[],
      targetOwner: {} as IUser,
      userList: {} as IPagedList
    }
  },
  mounted() {
    JQuery('#reassign').on('show.bs.modal', this.showModal)
    JQuery('#reassign').on('hide.bs.modal', this.hideModal)
  },
  methods: {
    ...mapActions([
      'fetchUsers'
    ]),
    hideModal() {
      this.targetOwner = {} as IUser
    },
    reassignItems() {
      this.$emit('change-owner', this.targetOwner)
      JQuery('#reassign').modal('hide')
    },
    showModal() {
      this.reassignIds = JQuery('#reassign').data('bs-reassign-ids') || []
      this.fetchUsers({ pageInfo: {  pageSize: 250, sortedBy: 'fullName', sortDirection: 'Ascending' } }).then(data => { this.userList = { ...data, items: data.items.map(item => { return { ...item, name: item.fullName } }) } })
    }
  }
})